<template>
<div>
    <b-row>
        <b-col lg="3">
            <CWidgetDropdown color="primary" :header="estadisticos.totalPosiblesClientes" text="Posibles clientes">
                <template #default>

                </template>
                <template #footer>
                    <CChartLineSimple pointed class="mt-3 mx-3" style="height:70px" :data-points="[65, 59, 84, 84, 51, 55, 40]" :options="{tooltips:{enabled:false}}" point-hover-background-color="primary" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="3">
            <CWidgetDropdown color="info" :header="estadisticos.totalClientes" text="Clientes">
                <template #default>

                </template>
                <template #footer>
                    <CChartLineSimple pointed class="mt-3 mx-3" style="height:70px" :data-points="[1, 18, 9, 17, 34, 22, 11]" point-hover-background-color="info" :options="{ elements: { line: { tension: 0.00001 }},tooltips:{enabled:false}}" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="3">
            <CWidgetDropdown color="warning" :header="estadisticos.totalOfertas" text="Ofertas">
                <template #default>

                </template>
                <template #footer>
                    <CChartLineSimple class="mt-3" style="height:70px" background-color="rgba(255,255,255,.2)" :data-points="[78, 81, 80, 45, 34, 12, 40]" :options="{ elements: { line: { borderWidth: 2.5 }},tooltips:{enabled:false}}" point-hover-background-color="warning" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="3">
            <CWidgetDropdown color="danger" :header="estadisticos.totalVentas" text="Ventas">
                <template #default>

                </template>
                <template #footer>
                    <CChartBarSimple class="mt-3 mx-3" style="height:70px" background-color="rgb(250, 152, 152)" :options="{tooltips:{enabled:false}}" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-money-bill-alt fa-md mr-1"></i> <span class="h5"> Últimas Ventas
                        <!-- {{$store.state}} --></span>
                    <!-- {{store.state.user}} -->
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">

                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <!-- <b-col md="12" v-if="skeleton==true">
                            <b-skeleton-table :rows="5" :columns="7" :table-props="{ bordered: true }"></b-skeleton-table>
                        </b-col> -->
                        <b-col md="12">
                            <b-table :items="listaVentas" :fields="campoVentas" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(total)="param">
                                    <span>S/. {{param.item.total}} </span>
                                </template>
                                <template v-slot:cell(cliente)="data">
                                    {{data.value | truncate(35)}}
                                </template>
                                <template #cell(fechaFinContrato)="param">
                                    <span class="mr-2">{{param.item.fechaFinContrato}}</span>
                                    <span class="h5" v-if="validarFecha(param)">
                                        <b-badge variant="danger">!</b-badge>
                                    </span>
                                </template>
                                <template #cell(acciones)="param">
                                    <b-button class="mr-1 mb-1" size="sm" variant="blue" @click="param.toggleDetails" v-c-tooltip="'Servicios'">
                                        <i :class="`fas fa-eye${param.detailsShowing ? '-slash' : ''} my-0 mx-0`"></i>
                                    </b-button>
                                    <b-button @click="verDetalleVenta(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button @click="eliminarVenta(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #row-details="row">
                                    <b-row>
                                        <b-col md="3">
                                            <span class="h6"> Servicios contratados: </span>
                                        </b-col>
                                        <b-col md="9">
                                            {{String(row.item.listaServicios.map(x => x.servicio).join(', '))}}
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-2">
                                        <b-col md="3">
                                            <span class="h6"> Incluye colaborador: </span>
                                        </b-col>
                                        <b-col md="9">
                                            <span v-if="row.item.conColaborador=='2'">SI</span>
                                            <span v-else-if="row.item.conColaborador=='1'">NO</span>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination class="float-right my-0" size="sm" v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
    CChartLineSimple,
    CChartBarSimple
} from '../charts/index.js'

import firebase from 'firebase';
const dbVentas = firebase.firestore().collection('ventas');
const dbClientes = firebase.firestore().collection('clientes');
const dbServicios = firebase.firestore().collection('servicios');

export default {
    components: {
        CChartLineSimple,
        CChartBarSimple
    },
    data() {
        return {
            estadisticos: {
                totalPosiblesClientes: '',
                totalClientes: '',
                totalOfertas: '',
                totalVentas: '',
            },
            campoVentas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "cliente",
                    label: "Cliente",
                    class: "text-center",
                },
                {
                    key: "fechaVenta",
                    label: "Inicio contrato",
                    class: "text-center",
                },
                {
                    key: "fechaFinContrato",
                    label: "Finalización",
                    class: "text-center",
                },
                {
                    key: "total",
                    label: "Importe",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaVentas: [],

            totalRows: 0,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        onFiltered(itemsFiltrados) {
            this.totalRows = itemsFiltrados.length
            this.currentPage = 1
        },
        validarFecha(param) {
            return moment(param.item.fechaFinContrato).isSameOrBefore(moment().format('YYYY-MM-DD'))
        },
        listarVentas() {
            let me = this;
            dbVentas.where("activo", "==", 2).where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.listaVentas = [];
                    querySnapshot.forEach((doc) => {
                        dbClientes.doc(doc.data().idCliente).get().then((docCliente) => {
                            let servicios = [];
                            for (const i in doc.data().listaServicios) {
                                dbServicios.doc(doc.data().listaServicios[i].idServicio)
                                    .onSnapshot((docServ) => {
                                        servicios.push({
                                            servicio: docServ.data().descripcion
                                        })
                                    });
                            }
                            me.listaVentas.push({
                                idVenta: doc.id,
                                cliente: docCliente.data().razonSocial,
                                fechaVenta: doc.data().fechaVenta,
                                fechaInicioContrato: doc.data().fechaInicioContrato,
                                fechaFinContrato: doc.data().fechaFinContrato,
                                conColaborador: doc.data().conColaborador,
                                listaServicios: servicios,
                                total: doc.data().total,
                                estado: doc.data().estado,
                            });

                        }).catch((error) => {
                        });

                    });
                    me.listaVentas.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        contarVentas() {
            let me = this;
            dbVentas.where("activo", "==", 2).where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.estadisticos.totalVentas = String(querySnapshot.size);
                });
            dbVentas.where("activo", "==", 1).where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.estadisticos.totalOfertas = String(querySnapshot.size);
                });
        },

        contarClientes() {
            let me = this;
            dbClientes.where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.estadisticos.totalClientes = String(querySnapshot.size);
                });
            dbClientes.where("estado", "==", 3)
                .onSnapshot((querySnapshot) => {
                    me.estadisticos.totalPosiblesClientes = String(querySnapshot.size);
                });
        },
        verDetalleVenta(param) {
            let me = this;
            me.$router.push({
                name: 'Gestión de venta master',
                params: {
                    id: param.item.idVenta,
                }
            });
        },
        eliminarVenta(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la venta?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbVentas.doc(param.item.idVenta).update({
                            estado: 1,
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                        })
                        .catch((error) => {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    mounted() {
        let me = this;
        me.listarVentas();
        me.contarVentas();
        me.contarClientes();
    }
}
</script>

<style>

</style>
